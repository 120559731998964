<template>
  <div>
    <AtomLogo />
    <MoleculeCard size="md">
      <MoleculeForm
        :validator="$v"
        :loading="submitLoading"
        :disabled="submitLoading"
        @submit="submitHandler"
      >
        <div class="text-center">
          <h2 class="card-title mb-2">{{ $t('pages.login.title') }}</h2>
          <p class="text-muted fs-h5 mb-2">{{ $t('pages.login.description') }}</p>
        </div>
        <AtomInput
          v-model.trim="form.email"
          label="email"
          class="mb-3"
        />
        <AtomInput
          v-model.trim="form.password"
          label="password"
          type="password"
          class="mb-2"
        >
          <template slot="labelDescription">
            <router-link
              to="/password/forgot"
              tabindex="1"
            >
              {{ $t('password.question.forgot') }}
            </router-link>
          </template>
        </AtomInput>
        <!-- <div class="mb-2">
          <label class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="remember"
            >
            <span class="form-check-label">Запомнить меня</span>
          </label>
        </div> -->
        <div class="form-footer">
          <AtomButton
            type="submit"
            color="primary"
            class="w-100"
            :disabled="$v.$anyError"
          >
            {{ $t('action.enter') }}
          </AtomButton>
        </div>
      </MoleculeForm>
    </MoleculeCard>
    <div class="text-center text-muted mt-3">
      {{ $t('login.missing') }}
      <router-link
        to="/register"
        tabindex="-1"
      >
        {{ $t('action.register') }}
      </router-link>
    </div>
    <div class="text-center text-muted mt-3">
      {{ $t('support.need.question') }}
      <a
        :href="$telegramLink"
        target="_blank"
        tabindex="-1"
      >
        {{ $t('support.need.answer') }}
      </a>
    </div>
  </div>
</template>

<script>
import { email, required, minLength } from 'vuelidate/lib/validators'

import MoleculeCard from '@/components/MoleculeCard'
import MoleculeForm from '@/components/MoleculeForm'
import AtomInput from '@/components/AtomInput'
import AtomButton from '@/components/AtomButton'
import AtomLogo from '@/components/AtomLogo'

export default {
  title: vm => vm.$t('pages.login.title'),

  components: {
    MoleculeCard,
    MoleculeForm,
    AtomInput,
    AtomButton,
    AtomLogo,
  },

  data: () => ({
    form: {
      email: '',
      password: '',
    },

    submitLoading: false,
  }),

  validations: {
    form: {
      email: { email, required },
      password: { required, minLength: minLength(6) },
    },
  },

  mounted () {
    if (this.$route.query.message && this.$te('error.' + this.$route.query.message)) {
      this.$notify({
        title: this.$t('action.warning'),
        text: this.$t('error.' + this.$route.query.message),
        type: 'warning',
      })
    }
  },

  methods: {
    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.submitLoading = true
        await this.$store.dispatch('login', this.form)
        this.$router.push('/')
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('login.success.message'),
          type: 'success',
        })
      } catch {} finally {
        this.submitLoading = false
      }
    }
  },
}
</script>
